//for mat-button
.app-menu-item {

  &.mat-mdc-menu-item.primary {
    color: var(--color-primary);

    .mat-icon {
      color: var(--color-primary);
    }
  }
}
