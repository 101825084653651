/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/images/icons/iconfont/material-icons.css";
@import "app/system/main/map/measure-manager/measure-ol-styles.scss";
@import "variables";
@import "projects/common-module/src/lib/app-styles/common";
@import "projects/common-module/src/lib/app-styles/buttons";
@import "projects/common-module/src/lib/app-styles/theming";
@import "projects/common-module/src/lib/app-styles/backgrounds";
@import "projects/common-module/src/lib/app-styles/spacing";
@import "projects/common-module/src/lib/app-styles/borders";
@import "projects/common-module/src/lib/app-styles/texts";
@import "projects/common-module/src/lib/app-styles/flexes";
@import "projects/common-module/src/lib/app-styles/cursors";
@import "projects/common-module/src/lib/app-styles/opacities";
@import "projects/common-module/src/lib/app-styles/app-dialog";
@import "projects/common-module/src/lib/app-styles/forms";
@import "projects/common-module/src/lib/app-styles/fonts";
@import "projects/common-module/src/lib/app-styles/chips";
@import "projects/common-module/src/lib/app-styles/tabs";
@import "projects/common-module/src/lib/app-styles/select";
@import "projects/common-module/src/lib/app-styles/menu";
@import "projects/common-module/src/lib/app-styles/ngx-daterangepicker";


* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
  font-family: $font-family;
}

p {
  font-size: 1.4rem;
}

li {
  font-size: 1.4rem;
  margin-top: 4px;
}

body {
  height: 100%;
  margin: 0;
}

.sidenav-container .sidenav .mat-drawer-inner-container {
  overflow: visible;
}

.accordion .mat-expansion-panel-body { // for unit-list
  padding: 0;
}

/** specific styles for DIALOG-container  */

.toolbar-dialog-container-by-footer .mat-mdc-dialog-container { // for toolbar if open by footer component
  min-height: $height-dialog-basic;
  overflow: auto;
}

.notification-dialog .mat-mdc-dialog-container { // for notification-dialog if open by notification component
  padding: 1vh;
}

.rule-dialog { // for rule-dialog if open by notification component
  height: 90vh;
}

.map-dialog { // for map-dialog
  width: 95%;
  height: 90vh;
}

.check-device .mat-mdc-dialog-container {
  background-color: var(--color-primary);
}

.stepper-in-dialog { // for rule-dialog, planning-dialog components
  font-family: $font-family;
  font-size: 1.4rem;

  .mat-horizontal-content-container {
    padding: 0 10px;
  }

  .mat-horizontal-stepper-header {
    height: 50px;
  }
}

/* end specific styles for dialog-container  **/

/** отключение подсветки последней нажатой кнопки */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  background-color: transparent !important;
}

.violations-table .toolbar.select-only-time {
  .calendar-table {
    pointer-events: none;
    cursor: auto;
  }

  .calendar-time .select .select-item {
    cursor: pointer;
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}

.mat-mdc-tooltip-trigger { // FOR ALL mat-tooltip
  touch-action: auto !important;
}

.big-multiline-tooltip {
  max-width: 300px !important;
  white-space: pre-wrap;
}

div.mat-mdc-menu-panel { // for all mat-menu
  max-width: 320px;
  min-width: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-accent) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.box-select-all .mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background,
.box-select-all .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--color-accent) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.box-select-all .mat-mdc-checkbox .mat-checkbox-frame {
  border-color: var(--color-accent) !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-basic) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-accent) !important;
}

.mat-mdc-form-field.mat-focused {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: var(--color-basic);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    background-color: var(--color-basic);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-required-marker {
    color: var(--color-basic);
  }

  .mat-mdc-input-element {
    color: var(--color-basic);
  }
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mat-mdc-input-element {
    color: var(--color-red);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: var(--color-red);
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-required-marker {
      color: var(--color-red);
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    background-color: var(--color-red);
  }
}

.mat-datepicker-toggle.mat-datepicker-toggle-active { // change default style angular material picker
  color: var(--color-accent);
}

/** styles for trip-chart, parking-chart, report */
.box-table table {
  width: 100%;

  tbody tr:hover:not(.not-hover) {
    background-color: var(--color-divider);
  }
}

.box-table .highlight-row {
  color: var(--color-white);
  background-color: var(--color-basic);
}

.box-table tr.highlight-row-border {
  box-shadow: 0 0 0 2px var(--color-primary) inset;

  .mat-mdc-cell {
    color: var(--color-primary);
  }
}

.box-table th {
  font-weight: bolder;
  color: var(--color-primary);
}

.box-table tr.mat-mdc-header-row {
  height: $height-header-row; // change default angular-material style
}

.box-table th.mat-mdc-header-cell {
  text-align: center;

  .mat-sort-header-container {
    justify-content: center;
  }
}

.box-table .mat-mdc-cell {
  padding: 2px;
  font-size: 1.3rem;
  text-align: center;
  cursor: pointer;
}

.box-table .not-hover .mat-mdc-cell {
  cursor: auto;
}

.box-table .logo-title {
  padding: 2px;
  font-weight: bold;
}

.box-table .total {
  text-align: center;
  font-weight: bold;

  .mat-mdc-footer-cell {
    font-size: 1.3rem;
    text-align: center;
  }
}

.box-table td.mat-mdc-cell:last-of-type,
.box-table td.mat-mdc-footer-cell:last-of-type,
.box-table th.mat-mdc-header-cell:last-of-type {
  padding-right: 0; // change default angular-material style
}

.box-table td.mat-mdc-cell:first-of-type,
.box-table td.mat-mdc-footer-cell:first-of-type,
.box-table th.mat-mdc-header-cell:first-of-type {
  padding-left: 0; // change default angular-material style
}

.box-table.border td.mat-mdc-cell,
.box-table.border td.mat-mdc-footer-cell,
.box-table.border th.mat-mdc-header-cell {
  border-right: 1px solid var(--color-divider);
}

.box-table.border .total td.mat-mdc-cell,
.box-table.border .total td.mat-mdc-footer-cell {
  border-bottom: 2px solid var(--color-primary);
}

.box-table {

  .box-with-icon {
    display: flex;
    align-items: center;
  }
}

/* end styles for trip-chart, parking-chart, report **/

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
mat-option.report-name .mat-option-text {
  color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-select-panel .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-primary);
  background-color: var(--color-minor);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.report-selector .mat-form-field-infix {
  width: auto; // for report-options
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.track-form .mat-form-field-infix {
  width: auto; // for track-options
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.speed-chart .mat-tab-label {
  padding: 3px; // for header-speed-chart
  height: 20px; // for header-speed-chart
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.group-selector:not(.mat-checked) .mat-slide-toggle-bar { // for side-nav, edit-rule
  background-color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle.toggle-button .mat-button-toggle-label-content { // for header-buttons
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  line-height: normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle.toggle-button.button_eq_s .mat-button-toggle-label-content { // for header-buttons
  padding: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.tabs-container .mat-tab-label.mat-tab-disabled { // tabs in fleet
  cursor: pointer;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-content {
    color: var(--color-text);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mode-button mat-button-toggle .mat-button-toggle-label-content { //for video-access-mode buttons
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/** styles for slider-zoom-map */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.slider-zoom .mat-slider-thumb {
  border: 2px solid var(--color-basic);
  border-radius: 0;
  background-color: var(--color-white);
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.slider-zoom .mat-slider-track-wrapper {
  left: -2px;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
mat-slider.slider-zoom .mat-slider-ticks,
mat-slider.slider-zoom .mat-slider-track-wrapper,
mat-slider.slider-zoom .mat-slider-track-background,
mat-slider.slider-zoom .mat-slider-track-fill {
  width: 6px;
  border: 2px solid var(--color-basic);
}

/* end styles for slider-zoom-map **/

.custom-modalbox { // for unit-info_le_l
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    padding: 5px;
  }
}

/** styles for dialog group */
.columns {
  .list-option {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content {
      width: 100%;
    }
  }
}

.input-color {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    display: none;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    width: 160px;
  }
}

/* end styles for dialog group **/

.chat.mat-mdc-list-item { // for informing-dialog
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    width: 100%;
    padding: 3px !important;
  }
}

/** styles for search-address */
.mat-step:last-child div {
  margin-left: 0;
  padding: 0;
  white-space: normal;
}

.mat-mdc-select-panel-wrap .mat-select-panel {
  max-height: 320px;
}

.list-results > mat-option.mat-mdc-option {
  align-items: normal;
  height: $height-search-result-item;
  padding: 0;
  line-height: $height-search-result-item;
  font-size: 1.2rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
mat-option.mat-mdc-option:nth-child(even) {
  background-color: var(--color-almost-white);
}

mat-option.mat-mdc-option.not-available,
mat-option.mat-option.mat-selected:not(.mat-option-disabled).not-available {
  color: var(--color-divider);
}

.mat-step-icon {
  background-color: var(--color-primary) !important;
}

.vertical-stepper {
  .mat-vertical-content {
    padding-right: 5px;
  }

  .result-form-field {
    width: 100%;
    padding: 0 10px;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }
}

/* end styles for search-address **/

.mat-mdc-select-panel.select-panel-geofences { // for rule-dialog
  background-color: rgba(white, 0.7);
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
mat-option.mat-mdc-option.geofence-option { // for rule-dialog
  background-color: transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-select-panel .mat-mdc-option.geofence-option.mat-selected:not(.mat-option-disabled) { // for rule-dialog
  color: var(--color-primary);
  background-color: var(--color-light-opacity);
}

.mat-mdc-select-panel .mat-mdc-option.geofence-option:hover { // for rule-dialog
  background-color: var(--color-light-opacity);
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
mat-option.mat-mdc-option.geofence-option .mat-option-text { // for rule-dialog
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.without-underline .mat-form-field-underline { // for rule-dialog
  display: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.option-flag.mat-mdc-option .mat-option-text { //for header-setting-dialog
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-container .mat-tab-list .mat-ink-bar { //for report (mat-tab), for track
  background-color: var(--color-primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
mat-button-toggle-group.buttons div.mat-button-toggle-label-content { // for video-toolbar
  padding: 6px 3px;
  line-height: 1;
}

/** styles for report */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.tab-list .mat-tab-label {
  height: $height-report-tab-list;
}

.expansion-panel .mat-expansion-panel-header-description,
.expansion-panel .mat-expansion-panel-header-title {
  margin-right: 0;
}

.expansion-panel .mat-expansion-panel-body {
  padding: 3px;
}

/*  end styles for report **/

.notification-panel .mat-expansion-panel-body { // for notification-table
  padding: 0;
}

.notification-panel .mat-expansion-indicator::after { // for notification-table
  transform: rotate(225deg) !important; // revert-arrow
}

.mat-mdc-snack-bar-container.mat-snack-bar-center .mat-mdc-snack-bar-action { //for appMessageService
  color: var(--color-accent);
}

.description { //tooltip for track on map
  font-size: 1rem;
  margin: 3px;
  line-height: 1;
}

h3.description {
  font-size: 1.2rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.slider.mat-accent .mat-slider-thumb, //for track-slider animation trip
.slider.mat-accent .mat-slider-thumb-label,
.slider.mat-accent .mat-slider-track-fill {
  background-color: var(--color-accent);
}

// for mat-autocomplete color
.autocomplete-color {

  .option-no-color {
    height: 40px;
    padding: 5px 7px;
    line-height: normal;
  }

  .option-color-palette {
    padding: 0;
    height: auto;
    line-height: normal;
  }
}

.printed-title {
  text-align: center;
  font-family: $font-family;
  font-size: 1.6rem;
  font-weight: bold;
  word-wrap: normal;
  overflow: hidden;
}

.line-break-tooltip { // for notification-table, video-card, video-live
  white-space: pre-line;
}

.filter-video-list, // for mat-menu filter-video
.checkbox-item-list { // for common filter
  display: flex;
  flex-direction: column;
  align-items: center;

  .selection-list {
    width: 100%;
    max-height: 70vh;
    overflow: auto;
  }

  .action-button {
    margin: 3px;
    color: var(--color-white);
    background-color: var(--color-primary);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.penalty-slider.blue .mat-slider-thumb,
.penalty-slider.blue .mat-slider-thumb-label,
.penalty-slider.blue .mat-slider-track-fill {
  background-color: var(--color-map-basic);
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.penalty-slider.yellow .mat-slider-thumb,
.penalty-slider.yellow .mat-slider-thumb-label,
.penalty-slider.yellow .mat-slider-track-fill {
  background-color: var(--color-yellow);
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.penalty-slider.red .mat-slider-thumb,
.penalty-slider.red .mat-slider-thumb-label,
.penalty-slider.red .mat-slider-track-fill {
  background-color: var(--color-red);
}

.radio-group { // for ImportCriterionDialogComponent

  .radio-option {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label {
      justify-content: space-between;
      padding: 10px;
    }
  }
}

.not-show {
  display: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.container-cards .tabs-container .mat-tab-body-wrapper { // for VehicleComponent
  height: 100%;
}

.chart-table {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: $font-family;
  user-select: text;

  th {
    position: sticky;
    top: 0;
    padding: 3px;
    background-color: white;
  }

  td {
    padding: 3px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.selection-list .list-option.scrolling .mat-list-item-content { // for RuleDialog
  padding-right: 9px;
}

.warning-icon {
  color: var(--color-yellow);
}

.in-dialog-radio-option { // for child form warehouse

  padding: 3px;
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label {
    display: flex;
    justify-content: space-between;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  span.mat-radio-label-content {
    white-space: normal;
  }
}

.popup {
  background-color: var(--color-white);
  max-width: 150px;
  padding: 5px;
  color: var(--color-map-basic);
  font-size: 1.3rem;
}

.popup-white {
  background-color: white;
  max-width: 150px;
  padding: 3px;
  color: black;
  font-size: 1.1rem;
  border-radius: 4px;
}

.width-100-percent {
  width: 100%;
}

.height-100-percent {
  height: 100%;
}

.map-controls {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 9999;
}

.height-inherit {
  height: inherit;
}

.overflow-auto {
  overflow: auto;
}

.dialog-title {
  padding: 0 0 10px 0;
  text-align: center;
  font-family: $font-family;
  font-size: 1.8rem;
  font-weight: bold;
  overflow: hidden;

  &.to-left {
    margin-top: 10px;
    text-align: left;
  }

  .name {
    color: var(--color-accent);
  }
}

.dialog-subheader {
  padding: 10px 0;
  text-align: center;
  font-family: $font-family;
  font-size: 1.5rem;
  font-weight: bold;
  overflow: hidden;

  &.to-left {
    text-align: left;
  }

  &.additional-message {
    color: var(--color-accent);
  }
}

.overflow-auto {
  overflow: auto;
}

.mat-horizontal-content-container {
  padding: 0!important;
}
