.app-font {
  font-family: $font-family;
}

.app-font {
  font-family: $font-family;

  &.size-base {
    font-size: 1.5rem;
  }
}

//text-color for text and icon
.text-divider-color {
  color: var(--color-divider);
}

.text-secondary-color {
  color: var(--color-secondary);
}

.text-white {
  color: var(--color-white);
}

.text-yellow {
  color: var(--color-yellow);
}

.text-orange {
  color: var(--color-orange);
}

.text-green {
  color: var(--color-green);
}

.text-blue {
  color: var(--color-map-basic);
}

.text-red {
  color: var(--color-red);
}

.text-accent {
  color: var(--color-accent);
}

.text-primary-color {
  color: var(--color-primary);
}

.text-divider-color {
  color: var(--color-divider);
}

.text-map-basic-color {
  color: var(--color-map-basic);
}

.text-bold {
  font-weight: bold;
}

.text-lighter {
  font-weight: lighter;
}

.text-underline {
  text-decoration: underline;
}

.text-like-mdc-list {
  font-family: var(--mdc-list-list-item-label-text-font);
  line-height: var(--mdc-list-list-item-label-text-line-height);
  font-size: var(--mdc-list-list-item-label-text-size);
  font-weight: var(--mdc-list-list-item-label-text-weight);
  letter-spacing: var(--mdc-list-list-item-label-text-tracking);
  color: var(--mdc-list-list-item-label-text-color);
}
