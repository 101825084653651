@import "projects/user-portal/src/styles/variables";

.ol-tooltip {
  position: relative;
  padding: 4px 8px;
  color: var(--color-white);
  font-size: 1.2rem;
  font-family: $font-family;
  white-space: nowrap;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  opacity: $opacity;
}

.ol-tooltip-measure {
  font-weight: bold;
  opacity: 1;
}

.ol-tooltip-static {
  color: var(--color-white);
  border: 1px solid var(--color-white);
  background-color: var(--color-map-basic);
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  position: absolute;
  bottom: -6px;
  left: 50%;
  content: "";
  margin-left: -7px;
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.ol-tooltip-static:before {
  border-top-color: var(--color-map-basic);
}

.delete-measure-button {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  padding: 0;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  background-color: var(--color-map-basic);
  cursor: pointer;
}

.delete-measure-button::after {
  position: absolute;
  top: -25%;
  left: 0;
  content: "\2716";
  width: 100%;
  height: 100%;
  color: var(--color-white);
}

.delete-measure-button:hover {
  background-color: var(--color-white);
}

.delete-measure-button:hover::after {
  color: var(--color-map-basic);
}
