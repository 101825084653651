@import "projects/common-module/src/lib/app-styles/common-variables";

$width-side-nav: 350px;
$width-basic: 330px;
$width-button: 40px;
$width-map-buttons: 30px;
$width-arrow: 20px;
$width-toggle-arrow: 20px;
$width-video-card: 200px;
$width-search-address: 300px;
$width-max-unit-name: 130px;
$width-chart-legend: 250px; // not decrease value

$max-width-cell-address: 250px;
$min-width-toolbar: 210px;

$height-mat-icon: 24px;
$height-header: 60px;
$height-tab-header: 35px;
$height-toolbar: 55px;
$height-name-table: 50px;
$height-search: 55px;
$height-unit-info: 127px;
$height-track-info: 43px;
$height-track-info-chart: 20vh;
$height-total-track-info-chart: calc(#{$height-track-info-chart} + #{$height-track-info});
$height-geofence-reduced: #{$height-header + $height-toolbar + 100px};
$height-units-list-item: 38px;
$height-search-result-item: 35px;
$height-header-row: 27px;
$height-mechanism-norm-consumption-card: 170px;
$height-player-header: 60px;
$height-player-info: 60px;
$height-report-tab-list: 30px;
$height-mat-paginator: 56px;
$height-footer_le_l: 40px;

$height-toolbar-geofence-manager: 68px;

$opacity: 0.85;
