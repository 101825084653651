@import "common-variables";
@import "scrollbar";

/** styles for progress SPINNER */
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: var(--color-white);
  opacity: 0.5 !important;
}

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
  stroke: var(--color-accent) !important;
}

.dialog-box-buttons .button.delete .mat-mdc-progress-spinner circle,
.dialog-box-buttons .button.delete .mat-mdc-progress-spinner circle {
  stroke: var(--color-basic) !important;
}

/* end styles for progress spinner **/


/** styles for PAGINATOR */
.paginator .mat-mdc-paginator-container {
  min-height: 0;
}

.paginator_eq_s {

  .mat-mdc-paginator-container {
    padding: 0;
    flex-wrap: unset;

    .mat-mdc-paginator-page-size-label {
      display: none;
    }

    .mat-mdc-paginator-range-label {
      margin: 0;
    }
  }
}

/* end styles for paginator **/


.size-for-date-picker-dialog { // size dialog-container if open for full view date-range-picker
  width: 700px;
  min-height: 425px;

  &.increase-for-buttons {
    min-height: 500px;
  }
}

/* start DATE_RANGE_PICKER **/
.date-range-input div.md-drppicker {
  display: flex;

  .ranges ul li button.active {
    background-color: var(--color-basic);
    color: var(--color-white);
  }
}

.mat-calendar-body-label {
  opacity: 0;

  &[colspan="7"] {
    display: none;
  }
}

.range-below-input div.md-drppicker { // for date-range-picker, other styles in common.scss
  top: $height-header !important;
}

.date-range-input.column_le_l div.md-drppicker {
  display: block;
}

.date-range-input.position-buttons div.md-drppicker {
  padding-top: 50px;

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    margin: 3px;
    padding: 0 12px;
    text-transform: capitalize;
    box-shadow: none;
    background-color: var(--color-basic);
  }

  .btn.btn-default {
    left: 0;
    padding: 0 6px;
    background-color: var(--color-light-opacity);
  }
}

div.md-drppicker.shown {
  .btn,
  .btn-default {
    padding: 0 15px;
    border-radius: 4px;
    font-family: $font-family;
    font-size: 1.4rem;
    text-transform: capitalize;
    box-shadow: none;
  }

  .btn {
    color: var(--color-white);
    background-color: var(--color-primary);
  }

  .btn-default {
    margin: 5px 10px 0 10px;
    color: var(--color-text);
    background-color: var(--color-light-opacity);
    border: 1px solid var(--color-divider);
  }
}

.calendar-time .select-item option:disabled {
  display: none;
}

.date-range-input .md-drppicker .calendar {
  .available {
    color: var(--color-text);

    &.off {
      color: var(--color-minor);
    }

    &:not(.off) {
      &.active,
      &.active:hover {
        color: var(--color-white);
        background-color: var(--color-basic);
      }
    }
  }

  .disabled.off {
    color: var(--color-minor);
  }
}

.video-filters-container.more_m .date-range-input div.md-drppicker, // for filter-video-component user-portal
.retranslator-date-filter .date-range-input div.md-drppicker { // for retranslator-log-component admin-portal
  left: -140px !important;
}

.notification-filters-container.more_m .date-range-input { // for filter-notification-component user-portal
  div.md-drppicker {
    left: -85px !important;
  }
}

.details-box .toolbar { // for violations-table-component user-portal
  div.md-drppicker {
    top: auto !important;
    bottom: 30vh;
  }
}

/** end date-range-picker */

.error-message-snack-bar {
  border: 3px solid var(--color-accent);
  border-radius: 7px;
}

/** start styles for DIALOG */
.dialog-size-limit {

}

.dialog-increase-size {
  max-width: 90vw !important;
  max-height: 90vh;
  width: 90vw;
  height: 90vh;
}

/** end styles for DIALOG */

/** start styles for DIALOG FORM-CONTAINER */
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.form-container .mat-mdc-checkbox .mat-checkbox-ripple {
  height: 30px; //for remove scroll of form
}

/** end styles for DIALOG FORM-CONTAINER */

