.border-color-divider {
  border: var(--color-divider) solid 2px;
}

.border-color-red {
  border: var(--color-red) solid 2px;
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-4 {
  border-radius: 4px;
}
